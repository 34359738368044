import { Component, OnInit, Input } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { AreaGraph, AreaGraphView } from 'src/app/model/vertical-views';

const CANVAS_HEIGHT_PX = 230;
const PADDING_BOTTOM_CANVAS_PX = 25;
const PADDING_TOP_CANVAS_PX = 45;
const COMPUTED_CANVAS_HEIGHT_PX =
  CANVAS_HEIGHT_PX - PADDING_BOTTOM_CANVAS_PX - PADDING_TOP_CANVAS_PX;
const MINCHANGE_PX_OFFSET = 32;

@Component({
  selector: 'app-stacked-area-chart',
  templateUrl: './stacked-area-chart.component.html',
  styleUrls: ['./stacked-area-chart.component.scss'],
})
export class StackedAreaChartComponent implements OnInit {
  @Input() isLoading = true;
  @Input() selectedColumnIndex?: number;
  @Input() set graph(value: AreaGraph) {
    this._graph = value;
    this.initGraphOption();
  }
  public myChart: any;
  public options: EChartsOption = {};
  public mergeOptions: EChartsOption = {};

  private _graph: AreaGraph = {} as AreaGraph;
  private baseOptions: any = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
    },
    legend: {
      data: [],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: [],
      },
    ],
    axisLine: {
      show: false, // Hide full Line
    },
    xisTick: {
      show: false, // Hide Ticks,
    },
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: null,
  };

  get graph() {
    return this._graph;
  }

  constructor() {}

  ngOnInit(): void {
    this.initGraphOption();
  }
  public onChartInit(ec: any): void {
    this.myChart = ec;
    this.initChartEvents(ec);
  }

  private initChartEvents(chartInstance: any): void {
    chartInstance.getZr().on('click', (params: any) => {
      const pointInPixel = [params.offsetX, params.offsetY];
      const pointInGrid = chartInstance.convertFromPixel('grid', pointInPixel);
    });
  }

  private initGraphOption(): void {
    this.baseOptions.xAxis[0].data = this.mapXAxisData(this.graph.data);
    this.baseOptions.legend.data = this.generateLegend(this.graph.data);
    this.baseOptions.series = this.mapCurrentData(this.graph.data);
    this.options = { ...this.baseOptions };
    console.log(this.options);
  }

  private mapXAxisData(graphData: any[]): String[] {
    if (graphData?.[0]?.data?.length == 4) {
      return ['Q1', 'Q2', 'Q3', 'Q4'];
    } else {
      return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
    }
  }
  private mapCurrentData(graphData: any[]): any[] {
    return graphData.map((item, index) => this.mapCurrentDataItem(item, index));
  }
  private arrayOfColors = [
    '#918EF4',
    '#EB88DB',
    '#E3E5BC',
    '#00BBF9',
    '#00F5D4',
  ];
  private mapCurrentDataItem(item: any, index: any): any {
    return {
      name: item.colName,
      type: 'line',
      stack: 'Total',
      areaStyle: {
        color: this.arrayOfColors[index],
        opacity: '0.5',
      },
      emphasis: {
        focus: 'series',
      },
      data: item.data,
    };
  }
  private generateLegend(graphData: any[]): String[] {
    return graphData.map((item) => item.colName);
  }
}
